import fetch from 'auth/FetchInterceptor';

const InventoryService = {};

const api = 'inventory';

InventoryService.getAll = function (params) {
  return fetch({
    url: `${api}/fetch`,
    method: 'GET',
    params
  });
};

InventoryService.get = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'GET'
  });
};

InventoryService.post = function (data) {
  return fetch({
    url: `${api}`,
    method: 'POST',
    data: data
  });
};

InventoryService.postClient = function (data) {
  return fetch({
    url: `${api}/client`,
    method: 'POST',
    data: data
  });
};

InventoryService.postConsumer = function (data) {
  return fetch({
    url: `${api}/consumer`,
    method: 'POST',
    data: data
  });
};

InventoryService.put = function (id, data) {
  return fetch({
    url: `${api}/${id}`,
    method: 'PUT',
    data: data
  });
};

InventoryService.putInspection = function (id, data) {
  return fetch({
    url: `${api}/${id}/inspection`,
    method: 'PUT',
    data: data
  });
};

InventoryService.putAdminInspection = function (id, data) {
  return fetch({
    url: `${api}/${id}/inspection/admin`,
    method: 'PUT',
    data: data
  });
};

InventoryService.putAgentInspection = function (id, data) {
  return fetch({
    url: `${api}/${id}/inspection/agent`,
    method: 'PUT',
    data: data
  });
};

InventoryService.downloadPdf = function (id) {
  return fetch({
    url: `${api}/${id}/generate-pdf`,
    method: 'POST'
  });
};

InventoryService.assign = function (id, data) {
  return fetch({
    url: `${api}/${id}/assign`,
    method: 'PUT',
    data: data
  });
};

InventoryService.approve = function (id, data) {
  return fetch({
    url: `${api}/${id}/approve`,
    method: 'PUT',
    data: data
  });
};

InventoryService.request = function (id, data) {
  return fetch({
    url: `${api}/${id}/request`,
    method: 'PUT',
    data: data
  });
};

InventoryService.generateFolderImage = function (id) {
  return fetch({
    url: `${api}/${id}/generate-folder/images`,
    method: 'PUT'
  });
};

InventoryService.generateFolderVideo = function (id) {
  return fetch({
    url: `${api}/${id}/generate-folder/videos`,
    method: 'PUT'
  });
};

InventoryService.getReportDetails = function (certificationId) {
  return fetch({
    url: `${api}/${certificationId}/public`,
    method: 'GET'
  });
};

InventoryService.generateMIS = function (data) {
  return fetch({
    url: `${api}/generate-mis`,
    method: 'POST',
    data
  });
};

export default InventoryService;
