import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AUTH_ROLES,
  AUTH_TOKEN,
  AUTH_TYPE,
  AUTH_ID,
  AUTH_NAME
} from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import { useEffect } from 'react';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  userId: localStorage.getItem(AUTH_ID) || null,
  userName: localStorage.getItem(AUTH_NAME) || null,
  token: localStorage.getItem(AUTH_TOKEN) || null,
  type: localStorage.getItem(AUTH_TYPE) || '',
  roles: JSON.parse(localStorage.getItem(AUTH_ROLES)) || {}
};

export const signIn = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
  
    const { authinfo, password } = data;
    try {
      const response = await AuthService.login({ authinfo, password });

      if(response.data.user.type==="VENDOR"){
        return rejectWithValue('Invalid Login');
      }

      // const { sessionToken: token, type, roles, id, user } = response.data;
      const { sessionToken: token,user: { type, roles, id, username } } = response.data;
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_TYPE, response.data.user.type);
      localStorage.setItem(AUTH_ID, response.data.user._id);
      localStorage.setItem(AUTH_NAME, response.data.user.username);

      let mappedRoles = {};
      if (roles) {
        mappedRoles = {};
        roles.forEach(({ key, ...role }) => {
          mappedRoles[key] = role;
        });
      }
      localStorage.setItem(AUTH_ROLES, JSON.stringify(mappedRoles));
      return { token, type, roles: mappedRoles, id };
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const signUp = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.register({ email, password });
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const signOut = createAsyncThunk('auth/logout', async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_TYPE);
  localStorage.removeItem(AUTH_ROLES);
  localStorage.removeItem(AUTH_ID);
  localStorage.removeItem(AUTH_NAME);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  'auth/signInWithFacebook',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = '/';
      state.userId = action.payload.id;
      state.token = action.payload.token;
      state.type = action.payload.type;
      state.userName = action.payload.user;
      state.roles = action.payload.roles;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = '';
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.type = null;
      state.userName = null;
      state.userId = null;
      state.roles = {};
      state.redirect = '/';
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.userId = action.payload.id;
        state.token = action.payload.token;
        state.userName = action.payload.user;
        state.type = action.payload.type;
        state.roles = action.payload.roles;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.userId = null;
        state.type = null;
        state.userName = null;
        state.roles = {};
        state.redirect = '/';
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.type = null;
        state.userName = null;
        state.roles = {};
        state.userId = null;
        state.redirect = '/';
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  }
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess
} = authSlice.actions;

export default authSlice.reducer;
