import { useParams } from 'react-router-dom';
import './Report.css';
import { useEffect } from 'react';
import { useState } from 'react';
import InventoryService from 'services/InventoryService';
import { Spin } from 'antd';

const ImagesKeys = [
  'profileImage',
  'leftFenderImage',
  'rightFenderImage',
  'rightSideImage',
  'rightSideFrontEdgeImage',
  'rightSideRearEdgeImage',
  'rightSideRightEdgeImage',
  'rightQuarterPanelImage',
  'rearSideImage',
  'rearSideLeftEdgeImage',
  'rearQuarterPanelImage',
  'leftSideImage',
  'leftSideRearEdgeImage',
  'leftSideFrontEdgeImage',
  'leftSideProfilePicImage',
  'frontViewImage',
  'engineCompartmentImage',
  'bootImage',
  'frontWindScreenImage',
  'interiorWindScreenImage',
  'odometerReadingImage',
  'abcPedalsImage',
  'chassisNumberImage',
  'rcFrontImage',
  'rcBackImage'
];

const GroupContainer = ({ children }) => {
  return (
    <div className='reportContainer'>
      <div className='reportHeader'>
        <img src={'/img/logo.png'} alt='Vehicle Mall' title='Vehicle Mall' />
        <div className='reportHeader-btn'>
          <h1>Vehicle Inspection Report</h1>
          <a href='#'>Accident</a>
        </div>
      </div>
      {children}
    </div>
  );
};

const Group1 = ({ clientName, vehicleInfo }) => {
  const infoCardsData = [
    {
      key: 'engineNumber',
      title: 'Engine Number'
    },
    {
      key: 'chassisNumber',
      title: 'Chassis Number'
    },
    {
      key: 'refORLoanNumber',
      title: 'Loan No/Ref No'
    },
    {
      key: 'rcAvailable',
      title: 'RC Available'
    },
    {
      key: 'insuranceType',
      title: 'Type of Insurance'
    },
    {
      key: 'insuranceValidityDate',
      title: 'Insurance Validity date'
    },
    {
      key: 'hypothecation',
      title: 'Is the car under Hypothecation'
    },
    {
      key: 'financerName',
      title: 'If Yes Name of finances'
    },
    {
      key: 'CNGLPGFitment',
      title: 'CNG LPG Fitment'
    },
    {
      key: 'CNGLPGFitmentEndorsement',
      title: 'CNG LPG Fitment Endorsement RC'
    },
    {
      key: 'CNGLPGRemoval',
      title: 'CNG LPG Removal'
    },
    {
      key: 'roadTax',
      title: 'Road tax paid'
    },
    {
      key: 'transmission',
      title: 'Transmission'
    },
    {
      key: 'inventoryCustomerName',
      title: 'Customer Name'
    },
    {
      key: 'CNGValidityDate',
      title: 'CNG Validity Date'
    },
    {
      key: 'signeeName',
      title: 'Ownership'
    }
  ];

  const ScoreSliderItem = ({ title, score }) => (
    <section className='score-item'>
      <div className='score-item-head'>
        <h4>{title}</h4>
        <h3>Average</h3>
      </div>
      <input
        type='range'
        step='any'
        min='0'
        max='1'
        value={score}
        onChange={() => {}}
      />
    </section>
  );

  const scoreList1 =
    vehicleInfo?.questionCategoryScores?.slice(
      0,
      vehicleInfo?.questionCategoryScores?.length / 2 + 1
    ) || [];

  const scoreList2 =
    vehicleInfo?.questionCategoryScores?.slice(
      scoreList1.length,
      vehicleInfo?.questionCategoryScores?.length
    ) || [];

  return (
    <GroupContainer>
      <div className='container-1'>
        <h1>Client Name: {clientName} </h1>
        <div className='sub-container-1'>
          <table border={1}>
            <tbody>
              <tr>
                <th colSpan={2}>Vehicle Information</th>
              </tr>
              <tr>
                <td>Chassis number</td>
                <td>{vehicleInfo?.chassisNumber}</td>
              </tr>
              <tr>
                <td>Make</td>
                <td>{vehicleInfo?.make}</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>{vehicleInfo?.model}</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>{vehicleInfo?.version}</td>
              </tr>
              <tr>
                <td>Mfg Year</td>
                <td>{vehicleInfo?.mfgYear}</td>
              </tr>
              <tr>
                <td>Fuel Type</td>
                <td>{vehicleInfo?.fuelType}</td>
              </tr>
              <tr>
                <td>Color</td>
                <td>{vehicleInfo?.color}</td>
              </tr>
              <tr>
                <td>Registration Date</td>
                <td>{vehicleInfo?.registrationDate}</td>
              </tr>
              <tr>
                <td>Odometer Reading</td>
                <td>{vehicleInfo?.odometerReading}</td>
              </tr>
            </tbody>
          </table>
          <section>
            <div className='head'>
              <h1>{vehicleInfo?.vehicleName}</h1>
            </div>
            <div className='tail'>
              <img
                src={vehicleInfo?.vehicleImage}
                loading='lazy'
                alt='Vehicle'
              />
            </div>
          </section>
        </div>
        <ul className='info-cards'>
          {infoCardsData.map((item, index) => (
            <li key={item.key}>
              <h1>{vehicleInfo[item?.key] || 'N/A'}</h1>
              <p>{item.title}</p>
            </li>
          ))}
        </ul>
        <div className='vehicle-price'>
          <div className='title'>
            <img
              src={'/img/logo.png'}
              alt='Vehicle Mall'
              title='Vehicle Mall'
            />
            <h3>Vehicle Mall True Price:</h3>
          </div>
          <h2>₹{vehicleInfo?.truePrice}</h2>
        </div>
        <section className='summery-wrapper'>
          <div className='head'>
            <h4>Summary</h4>
            <div className='score'>
              <h4>Overall Score </h4>
              <div className='score-text'>
                <h5>
                  {vehicleInfo?.overallScore} <span>/10</span>
                </h5>
              </div>
            </div>
          </div>
          <div className='tail'>
            <div className='col'>
              {scoreList1.map((item, index) => (
                <ScoreSliderItem
                  key={item?.id}
                  title={item?.name}
                  score={item?.score}
                />
              ))}
            </div>
            <div className='col'>
              {scoreList2.map((item, index) => (
                <ScoreSliderItem
                  key={item?.id}
                  title={item?.name}
                  score={item?.score}
                />
              ))}
            </div>
          </div>
        </section>
        <section className='images'></section>
      </div>
    </GroupContainer>
  );
};

const Group2 = ({ vehicleInfo }) => {
  // data = { key: "", value: "" }
  const TableDetails = ({ title, data }) => {
    const list1 = data?.slice(0, data.length / 2 + 1) || [];

    const list2 = data?.slice(list1.length, data.length) || [];

    return (
      <table border={1} className='table-details'>
        <tbody>
          <tr>
            <th colSpan={2}>{title}</th>
          </tr>
          <tr>
            <td>
              {list1?.map((item, index) => (
                <div className='col'>
                  <img
                    src='https://car-evaluation.s3.amazonaws.com/vehicle-mall/bullet.png'
                    alt=''
                    loading='lazy'
                  />
                  <p>
                    {item?.question} : <strong> {item?.answer} </strong>
                  </p>
                </div>
              ))}
            </td>
            <td>
              {list2?.map((item, index) => (
                <div className='col'>
                  <img
                    src='https://car-evaluation.s3.amazonaws.com/vehicle-mall/bullet.png'
                    alt=''
                    loading='lazy'
                  />
                  <p>
                    {item?.question} : <strong> {item?.answer} </strong>
                  </p>
                </div>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  return (
    <GroupContainer>
      <div className='container-2'>
        <section className='remarks'>
          <h3>Remarks</h3>
          <div className='content'>
            <p>{vehicleInfo?.disclaimer}</p>
          </div>
        </section>
        {vehicleInfo?.questionCategories.map((item, index) => (
          <TableDetails
            key={item?.id}
            title={item?.name}
            data={item?.questions}
          />
        ))}
      </div>
    </GroupContainer>
  );
};

// images = [{ src: "", alt: "", title: "" }]
const ImagesGroup = ({ vehicleInfo }) => {
  return (
    <GroupContainer>
      <ul className='images-list'>
        {ImagesKeys.map((item, index) =>
          vehicleInfo?.[item] ? (
            <li>
              <img src={vehicleInfo?.[item]} loading='lazy' alt='Vehicle' />
              <p>{item}</p>
            </li>
          ) : undefined
        )}
      </ul>
    </GroupContainer>
  );
};

const VehicleInspectionReport = () => {
  const { certificationId } = useParams();
  const [inspectionData, setInspectionData] = useState(null);

  useEffect(() => {
    if (certificationId) {
      const fetchInspectionData = async () => {
        const response = await InventoryService.getReportDetails(
          certificationId
        );
        setInspectionData(response.data);
      };
      fetchInspectionData();
    }
  }, [certificationId]);

  return (
    <div className='page-wrapper'>
      {!inspectionData && (
        <Spin
          style={{
            margin: 'auto'
          }}
        />
      )}
      {inspectionData && (
        <>
          <Group1
            clientName={inspectionData?.userName}
            vehicleInfo={inspectionData}
          />
          <Group2 vehicleInfo={inspectionData} />
          <ImagesGroup vehicleInfo={inspectionData} />
        </>
      )}
    </div>
  );
};

export default VehicleInspectionReport;
